
const getters = {
  menuData: state => state.settings.menuData,
  craInfo: () => {
    let craInfo;
    if (process.env.NODE_ENV == "development") {
      craInfo = {
        t_cra_id: 1,
        txt_cra_name: "cui",
        password: "cxc123"
      }
    } else {
      craInfo = JSON.parse(sessionStorage.getItem("craInfo"));
    }
    return craInfo;
  },
  // 生成随机字符串
  UUID() { return 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[x]/g, function () { return (Math.random() * 16 | 0).toString(16); }); },
};

export default getters;