<template>
<div class="login-login" v-loading="loading">
  <div class="il-top column-center-center">欢迎登录药研有度运营管理平台</div>
  <div class="il-middle row-center-around">
    <div class="ilm-img"></div>
    <div class="ilm-form">
      <div class="ilmf-title">LOGIN</div>
      <div class="ilmf-contain">
        <div class="ilmfc-item">
          <span>用户名</span>
          <el-input v-model="username" placeholder="用户名"></el-input>
        </div>
        <div class="ilmfc-item">
          <span>登录密码</span>
          <el-input v-model="password" type="password" show-password placeholder="登录密码"></el-input>
        </div>
        <el-button class="ilmfc-btn" :disabled="isDisabled" @click="goLogin">登录</el-button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
// 登录页
import {getLogin} from "@/api/apis.js";
import { ElMessage } from "element-plus";
import { computed, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const state = reactive({
      username: "",
      password: "",
      isDisabled: computed(() => {
        if (state.username != "" && state.password != "") {
          return false;
        } else {
          return true;
        }
      }),
      loading: false
    });

    // 登录
    const goLogin = () => {
      state.loading = true;
      getLogin({
        txt_login_name: state.username,
        txt_login_psw: state.password,
        txt_clear_text: state.password
      }).then((response) => {
        if (response.code == 200) {
          // 更新缓存，考虑缓存有效期，跳转至首页
          response.data.password = state.password;
          // 更新缓存，用户信息 缓存有效期
          sessionStorage.setItem("craInfo", JSON.stringify(response.data));
          sessionStorage.setItem("lastLoginTime", JSON.stringify(new Date().getTime()));
          state.loading = false;
          router.replace({path: "/"});
        } else {
          state.loading = false;
          ElMessage.error(response.msg);
        }
      }).catch((err) => {
        console.error(err);
        state.loading = false;
      });
    }

    return {
      ...toRefs(state),
      goLogin
    }
  }
}
</script>

<style lang="scss" scoped>
.login-login {
  height: 100%;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .il-top {
    color: #1f225f;
    margin-top: 110px;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 14px;
  }

  .il-middle {
    margin-top: 30px;
    height: 60%;
    width: 80%;

    .ilm-img {
      height: 100%;
      width: 50%;
      background-image: url("../../assets/imgs/undraw.svg");
      background-size: 100% 100%;
    }

    .ilm-form {
      width: 30%;
      height: 60%;
      color: #333333;
      padding: 20px 20px;
      border-radius: 20px;
      box-shadow: 0 0 10px 2px rgba($color: #b1b0b0, $alpha: 1.0);

      .ilmf-title {
        font-weight: bold;
        font-size: 24px;
        text-align: center;
        margin-bottom: 14px;
      }

      .ilmf-contain {
        height: calc(100% - 50px);

        .ilmfc-item {
          color: #9f9e9e;

          .el-input {
            margin: 10px 0 24px 0;

            /deep/ .el-input__wrapper {
              border-radius: 20px;
              box-shadow: inset 0 0 2px 1px rgba($color: #b1b0b0, $alpha: 1.0);
            }
          }
        }

        .ilmfc-btn {
          width: 100%;
          border-radius: 20px;
          background-color: #8a88c3;
          color: #ffffff;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
